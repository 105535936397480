<template>
  <Toast/>

  <Button label="New User" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>

  <Dialog v-model:visible="userDialog" :style="{width: '450px'}" :header="formHeader" :modal="true" class="p-fluid">
    <!-- First Name -->
    <div class="field">
      <label for="first_name">First Name*</label>
      <InputText
        id="first_name" v-model.trim="v$.user.first_name.$model" required="true" autofocus
        :class="{'p-invalid': v$.user.first_name.$invalid && submitted}"
      />
      <small
        v-if="(v$.user.first_name.$invalid && submitted) || v$.user.first_name.$pending.$response"
        class="p-error">{{ v$.user.first_name.required.$message.replace('Value', 'First Name') }}
      </small>
    </div>

    <!-- Last Name -->
    <div class="field">
      <label for="last_name">Last Name*</label>
      <InputText
        id="last_name" v-model.trim="v$.user.last_name.$model" required="true" autofocus
        :class="{'p-invalid': v$.user.last_name.$invalid && submitted}"
      />
      <small
        v-if="(v$.user.last_name.$invalid && submitted) || v$.user.last_name.$pending.$response"
        class="p-error">{{ v$.user.last_name.required.$message.replace('Value', 'Last Name') }}
      </small>
    </div>

    <!-- Email -->
    <div class="field">
      <label for="email">Email*</label>
      <InputText
        id="email" v-model.trim="v$.user.email.$model" aria-describedby="email-error"
        :class="{'p-invalid': v$.user.email.$invalid && submitted}"
      />
      <span v-if="v$.user.email.$error && submitted">
        <span id="email-error" v-for="(error, index) of v$.user.email.$errors" :key="index">
          <small class="p-error">{{ error.$message.replace('Value', 'Email') }}</small>
        </span>
      </span>
      <small
        v-else-if="(v$.user.email.$invalid && submitted) || v$.user.email.$pending.$response"
        class="p-error">{{ v$.user.email.required.$message.replace('Value', 'Email') }}
      </small>
    </div>

    <!-- Status -->
    <div class="field" v-if="user.id">
      <label for="status">Status</label>
      <Dropdown id="status" :options="statuses" v-model="user.status"></Dropdown>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveUser(!v$.$invalid)" />
    </template>
  </Dialog>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import UserResource from '@http/UserResource';
import toastParamBuilder from "@/builders/ToastParamBuilder";
import user from '@models/User';

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  data() {
    return {
      userDialog: false,
      formHeader: '',
      user: {
        first_name: '',
        last_name: '',
        email: '',
        status: 0,
      },
      filters: {},
      submitted: false,
    }
  },

  props: {
    isSelectedEditForm: Boolean,
    selectedId: Number,
  },

  userResource: null,

  created() {
    this.userResource = new UserResource();
  },

  methods: {
    openNew() {
      this.user = {};
      this.formHeader = 'Create user';
      this.submitted = false;
      this.userDialog = true;
    },

    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },

    saveUser(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      if (this.user.id) {
        this.user.status = this.statusId(this.user.status);
        this.$store.dispatch('user/updateUser', { id: this.user.id, payload: this.user })
          .then(response => {
            this.$toast.add(toastParamBuilder.success(response));
            this.closeDialog();
          })
          .catch(error => {
            this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
          });
      } else {
        this.$store.dispatch('user/createUser', this.user)
          .then(response => {
            this.$toast.add(toastParamBuilder.success(response));
            this.closeDialog();
          })
          .catch(error => {
            this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
          });
      }
    },

    loadEdit(id) {
      this.formHeader = 'Update user';
      this.userResource.getUserById(id)
        .then(data => {
          this.user = data;
          this.user.status = this.statusText(data.status);
          this.submitted = false;
          this.userDialog = true;
        });
    },

    closeDialog() {
      this.userDialog = false;
      this.user = {};
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    }
  },

  computed: {
    statuses() {
      return user.statuses.map(item => item.name);
    },

    statusId() {
      return status => {
        const currentStatus = user.statuses.filter(item => item.name === status);
        return currentStatus[0].id;
      };
    },

    statusText() {
      return status => this.statuses[status];
    },
  },

  watch: {
    isSelectedEditForm(value) {
      if (value) {
        this.loadEdit(this.selectedId);
      }
    },

    userDialog(value) {
      if (! value) {
        this.$emit('hideDialog');
      }
    },
  },

  validations() {
    return {
      user: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
      }
    }
  },
}
</script>
