<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4">
          <h3>Users</h3>

          <div>
            <add-or-update-user
              :selectedId="openFormParams.selectedUserId"
              :isSelectedEditForm="openFormParams.isSelectedEditForm"
              @hide-dialog="formClosed"
            />

            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
            </span>
          </div>
        </div>

        <DataTable
          :value="users" :lazy="true" :paginator="true" :rows="pagination?.per_page ?? 25"
          :first="pagination?.from-1" :totalRecords="pagination.total" :loading="loading"
          @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event, ['status'])" filterDisplay="row"
          v-model:filters="filters" ref="dt" dataKey="id" :sortField="sort" :sortOrder="order"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>

          <!-- Name -->
          <Column field="name" header="Name" ref="name" :sortable="true"/>

          <!-- Email -->
          <Column field="email" header="Email" :sortable="true" />

          <!-- Orders Made -->
          <Column field="orders_count" header="Orders Made" :sortable="true" />

          <!-- Status -->
          <Column field="status" header="Status" :sortable="true" :showFilterMenu="false">
            <template #body="{data}">
              <span :class="'product-badge status-' + statusText(data.status).name">
                {{ statusText(data.status).name.toUpperCase() }}
              </span>
            </template>
            <template #filter="{filterModel,filterCallback}">
              <Dropdown
                v-model="filterModel.value" :options="statuses" @change="filterCallback()"
                placeholder="Any" class="p-column-filter" :showClear="true"
              >
                <template #value="slotProps">
                  <span v-if="slotProps.value" :class="'product-badge status-' + slotProps.value.name">
                    {{ slotProps.value.name }}
                  </span>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
                <template #option="slotProps">
                  <span :class="'product-badge status-' + slotProps.option.name">
                    {{ slotProps.option.name }}
                  </span>
                </template>
              </Dropdown>
            </template>
          </Column>

          <!-- Edit -->
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                @click="editUser(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import { mapGetters } from 'vuex';
import AddOrUpdateUser from './AddOrUpdateUser';
import user from '@/models/User';
import dataTable from "@/mixins/dataTable";
import store from "@store";
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  mixins: [dataTable],
  data() {
    return {
      openFormParams: {
        selectedUserId: 0,
        isSelectedEditForm: false,
      },
      filters: {
        status: {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    }
  },

  components: {
    AddOrUpdateUser,
  },

  created() {
    this.filters.status.value = this.statuses[this.$route.query?.status];
  },

  methods: {
    openNew() {
      this.user = {};
      this.submitted = false;
      this.productDialog = true;
    },

    editUser(user) {
      this.openFormParams.isSelectedEditForm = true;
      this.openFormParams.selectedUserId = user.id;
    },

    formClosed() {
      this.openFormParams.isSelectedEditForm = false;
    },
  },

  computed: {
    statusText() {
      return status => user.statuses?.find(i => i.id === status);
    },

    statuses() {
      return user.statuses;
    },

    ...mapGetters(
      'user', [
        'users',
        'pagination',
      ]
    ),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('user/getUsers', to.query);
      next((vm) => {
        vm.loading = false;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('user/getUsers', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
